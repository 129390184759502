export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true,
    perm: ['Admin', 'Master', 'Viewer']
  },
  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx bx-home-circle',
    link: '/',
    perm: ['Admin', 'Master', 'Viewer']
  },
  {
    id: 3,
    label: 'menuitems.admin.text',
    icon: 'mdi mdi-cog',
    perm: ['Admin'],
    subItems: [
      {
        id: 4,
        label: 'menuitems.admin.list.users',
        link: '/admin/users',
        parentId: 3
      }
      // {
      //   id: 5,
      //   label: 'menuitems.admin.list.perms',
      //   link: '/admin/roles',
      //   parentId: 3
      // }
    ]
  },
  {
    id: 6,
    label: 'menuitems.settings.text',
    isTitle: true,
    perm: ['Admin']
  },
  {
    id: 7,
    label: 'menuitems.setting.text',
    icon: 'mdi mdi-cog',
    perm: ['Admin'],
    subItems: [
      {
        id: 8,
        label: 'menuitems.setting.list.event',
        link: '/settings/event',
        parentId: 7
      },
      {
        id: 9,
        label: 'menuitems.setting.list.rsvp',
        link: '/settings/rsvp',
        parentId: 7
      },
      {
        id: 28,
        label: 'menuitems.setting.list.wa',
        link: '/settings/wa',
        parentId: 7
      },
      {
        id: 10,
        label: 'menuitems.setting.list.site',
        link: '/settings/site',
        parentId: 7
      },
      {
        id: 11,
        label: 'menuitems.setting.list.forms',
        link: '/settings/forms',
        perm: ['Admin'],
        parentId: 7
      },
      {
        id: 12,
        label: 'menuitems.setting.list.email',
        link: '/settings/emails',
        perm: ['Admin'],
        parentId: 7
      },
      {
        id: 26,
        label: 'menuitems.setting.list.accreditation',
        link: '/settings/accreditation',
        perm: ['Admin'],
        parentId: 7
      },
      {
        id: 13,
        label: 'menuitems.setting.list.checkin',
        link: '/settings/checkin',
        perm: ['Admin'],
        parentId: 7
      },
      {
        id: 14,
        label: 'menuitems.setting.list.boxoffice',
        link: '/settings/ticket-office',
        perm: ['Admin'],
        parentId: 7
      },
      {
        id: 15,
        label: 'menuitems.setting.list.integration',
        link: '/settings/integration',
        perm: ['Admin'],
        parentId: 7
      }
    ]
  },
  {
    id: 16,
    label: 'menuitems.rsvp.text',
    isTitle: true,
    perm: ['Admin', 'Master', 'Operator', 'Viewer']
  },
  {
    id: 29,
    label: 'menuitems.ineps.text',
    icon: 'bx bx-purchase-tag-alt',
    link: '/ineps',
    perm: ['Admin']
  },
  {
    id: 27,
    label: 'menuitems.campaign.text',
    icon: 'bx bx-paper-plane',
    link: '/wa/campaign',
    perm: ['Admin']
  },
  {
    id: 17,
    label: 'menuitems.guests.text',
    icon: 'bx bx-user-pin',
    link: '/guests',
    perm: ['Admin', 'Master', 'Viewer']
  },
  {
    id: 18,
    label: 'menuitems.participants.text',
    icon: 'bx bxs-user-detail',
    link: '/participants',
    perm: ['Admin', 'Master', 'Viewer']
  },
  {
    id: 19,
    label: 'menuitems.check-in.text',
    isTitle: true,
    perm: ['Admin', 'Master', 'Operator']
  },
  {
    id: 21,
    label: 'menuitems.checkin.name',
    icon: 'fas fa-qrcode',
    link: '/check-in',
    perm: ['Admin', 'Master', 'Operator']
  },
  {
    id: 20,
    label: 'menuitems.checkin.statistics',
    icon: 'mdi mdi-apps-box',
    link: '/statistics-checkin',
    perm: ['Admin']
  },
  {
    id: 22,
    label: 'menuitems.organize.text',
    isTitle: true,
    perm: ['Admin']
  },
  {
    id: 23,
    label: 'menuitems.seats.text',
    icon: 'mdi mdi-gamepad-circle-outline',
    link: '/seating',
    perm: ['Admin']
  },
  {
    id: 24,
    label: 'menuitems.report.text',
    isTitle: true,
    perm: ['Admin']
  },
  {
    id: 25,
    label: 'menuitems.reports.text',
    icon: 'bx bxs-report',
    link: '/reports',
    perm: ['Admin']
  }
]
